import React, { useEffect } from "react";
import BarcodeGenerator from "../utility/component/barcodegenrater";
import "./../styles/print.css";
import { useDispatch } from "react-redux";
import { setSpinnerLoading } from "../utility/Redux/modal";
const PrintBarcodes = React.forwardRef(({ barcodes, byALIOS, style }, ref) => {
  const dispatch = useDispatch();
  console.log(barcodes);
  return (
    <div ref={ref}>
      <div
        id="print-component"
        style={{
          height: "fit",
        }}
      >
        {barcodes.map((o, index) => {
          return (
            <div key={index}>
              {[...Array(Number(o?.barQnty ? Number(o.barQnty) : 1))].map(
                () => {
                  return (
                    <div
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <BarcodeGenerator
                        style={style}
                        byALIOS={byALIOS}
                        value={o.ALIAS_CODE}
                        item={o}
                      />
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default PrintBarcodes;
