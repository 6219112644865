import React, { useRef, useEffect } from "react";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { FormatNumber2 } from "../functions/formatNumber";
import moment from "moment";

const BarcodeGenerator = ({ byALIOS, style, item }) => {
  const barcodeRef = useRef(null);

  //   useEffect(() => {
  //     if (barcodeRef.current) {
  //       html2canvas(barcodeRef.current).then((canvas) => {
  //         const img = canvas.toDataURL("image/png");
  //         onCapture(img); // Pass the image data URL to the parent component
  //       });
  //     }
  //   }, [value]);
  const va = byALIOS ? item.ALIAS_CODE : item.ICODE;
  console.log(va);
  return (
    <div
      ref={barcodeRef}
      className=""
      style={{
        width: "100%",
        display: "flex",
        height: "100%",
        padding: "8px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="flex-1">
        <div className="w-full flex justify-center">
          <Barcode
            fontSize={10}
            width={1}
            height={style.height}
            value={va}
            displayValue={false}
            margin={2}
          />
        </div>
        <div
          style={{
            fontSize: "10px",
          }}
        >
          {item.ICODE} - {item.ITEMNAME} [{`${item.UNIT} - ${item.PACKING}`}]
        </div>
        <div
          style={{
            fontSize: "10px",
          }}
          className="font-semibold"
        >
          {"₹" + " " + FormatNumber2(item.SALE_PRICE)}
        </div>

        {item.BATCH && item.EXP_DATE && (
          <div
            style={{
              fontSize: "10px",
            }}
          >
            {item.BATCH} - {item.EXP_DATE}
          </div>
        )}

        <div
          style={{
            fontSize: "10px",
          }}
          className="flex flex-1 justify-end "
        >
          {moment().format("DD-MM-YYYY")}
        </div>
      </div>
    </div>
  );
};

export default BarcodeGenerator;
