import { Fade, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FontSizeWidth } from "../utility/constant";
import { useSelector } from "react-redux";
import { selectWidth } from "../utility/Redux/profile";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import BarcodeGenerator from "../utility/component/barcodegenrater";
import ReactToPrint from "react-to-print";
import PrintBarcodes from "./printBarCodes";
import { Add, Remove } from "@mui/icons-material";
import Barcode from "react-barcode";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};

const BarcodeList = ({ status, onClose, list, component, printBarRef }) => {
  const columns = [
    {
      name: "Item Name",
      width: 200,
    },
    {
      name: "Purchase Price",
      width: 130,
    },
    {
      name: "Sale Price",
      width: 130,
    },
    {
      name: "Expiry Date",
      width: 130,
    },
    {
      name: "Batch",
      width: 130,
    },
    {
      name: "Qnty",
      width: 90,
    },
  ];
  const handleSelectChange = (event) => {
    setSelectedStyle(styles[event.target.value].value);
  };
  const styles = {
    style1: {
      name: "2/1 inch",
      value: { width: "192px", height: "96px" }, // 2/1 inch
    },
    style2: {
      name: "2/1.5 inch",
      value: { width: "192px", height: "144px" }, // 2/1.5 inch
    },
    style3: {
      name: "2/2 inch",
      value: { width: "192px", height: "192px" }, // 2/2 inch
    },
    style4: {
      name: "1.5/1 inch",
      value: { width: "144px", height: "96px" }, // 1.5/1 inch
    },
  };
  const [selectedStyle, setSelectedStyle] = useState(styles.style1.value);

  const [barcodeList, setBarCodeList] = useState([]);
  const [selectedBarList, setSelectedBarList] = useState([]);
  const width = useSelector(selectWidth);

  useEffect(() => {
    const l = list.filter((o) => o.ICODE && o.ITEMNAME);

    setBarCodeList(
      l.map((o) => {
        return {
          ...o,
          barQnty: Number(o.QNTY) + Number(o.FREE_QNTY),
        };
      })
    );

    setSelectedBarList(
      l.map((o) => {
        return {
          ...o,
          barQnty: Number(o.QNTY) + Number(o.FREE_QNTY),
        };
      })
    );
  }, [list, status]);

  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? "100ms" : "0ms",
        }}
      >
        <div style={style} className="overflow-hidden bg-transparent">
          <div className="flex bg-brown text-white justify-between overflow-hidden items-center min-w-[350px] p-2">
            <div>BarCode List</div>
            <button fontSize={"medium"} onClick={onClose}>
              {" "}
              <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
            </button>
          </div>

          <div className="p-2 bg-white rounded-b-sm">
            <div className="flex  overflow-hidden border-t border-r border-[#dbd9d9] border-b rounded-t-sm bg-gray-200">
              {columns.map((o, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      width: o.width,
                      fontSize: FontSizeWidth(width),
                    }}
                    className=" py-1 border-l border-[#dbd9d9] font-semibold  text-center"
                  >
                    {o.name}
                  </div>
                );
              })}
            </div>
            <div className=" border-l  border-[#dbd9d9] border-r max-h-[600px] overflow-y-auto">
              {barcodeList.map((o, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      fontSize: FontSizeWidth(width),
                    }}
                    className=" bg-gray-100 border-r   border-b flex border-[#dbd9d9] "
                  >
                    <div
                      style={{
                        width: columns[0].width,
                      }}
                      className="border-[#dbd9d9] border-r p-1 overflow-hidden whitespace-nowrap"
                    >
                      <input
                        type={"checkbox"}
                        className="mr-1"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedBarList((b) => {
                              return [...b, o];
                            });
                          } else {
                            setSelectedBarList((b) =>
                              b.filter((r) => r.ICODE !== o.ICODE)
                            );
                          }
                        }}
                        checked={
                          selectedBarList.find((r) => r.ICODE === o.ICODE)
                            ? true
                            : false
                        }
                        value={
                          selectedBarList.find((r) => r.ICODE === o.ICODE)
                            ? true
                            : false
                        }
                      />

                      {o.ITEMNAME}
                    </div>
                    <div
                      style={{
                        width: columns[1].width,
                      }}
                      className="text-right border-[#dbd9d9] border-r p-1"
                    >
                      {FormatNumber2(o.PUR_PRICE)}
                    </div>

                    <div
                      style={{
                        width: columns[2].width,
                      }}
                      className="text-right border-[#dbd9d9] border-r p-1"
                    >
                      {FormatNumber2(o.SALE_PRICE)}
                    </div>

                    <div
                      style={{
                        width: columns[3].width,
                      }}
                      className=" border-[#dbd9d9] border-r p-1"
                    >
                      {o.EXP_DATE}
                    </div>
                    <div
                      style={{
                        width: columns[4].width,
                      }}
                      className=" border-[#dbd9d9] border-r p-1"
                    >
                      {o.BATCH}
                    </div>
                    <div
                      style={{
                        width: columns[5].width,
                      }}
                      className=" border-[#dbd9d9] border-r hover:cursor-pointer  flex items-center"
                    >
                      <div
                        onClick={() => {
                          setBarCodeList((c) =>
                            c.map((b) => {
                              const q = b.barQnty - 1;

                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: q > 0 ? q : 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );

                          setSelectedBarList((c) =>
                            c.map((b) => {
                              const q = b.barQnty - 1;

                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: q > 0 ? q : 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );
                        }}
                        className="px-2 border-r h-full flex items-center bg-gray-100"
                      >
                        <Remove
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                      <input
                        type="number"
                        value={o.barQnty}
                        onChange={(e) => {
                          if (e.target.value) {
                            setSelectedBarList((c) =>
                              c.map((b) => {
                                if (b.ICODE === o.ICODE) {
                                  return {
                                    ...b,
                                    barQnty:
                                      e.target.value > 0 ? e.target.value : 1,
                                  };
                                } else {
                                  return b;
                                }
                              })
                            );
                            setBarCodeList((c) =>
                              c.map((b) => {
                                if (b.ICODE === o.ICODE) {
                                  return {
                                    ...b,
                                    barQnty:
                                      e.target.value > 0 ? e.target.value : 1,
                                  };
                                } else {
                                  return b;
                                }
                              })
                            );
                          }
                        }}
                        className="w-full  text-center bg-transparent outline-none"
                      />
                      <div
                        onClick={() => {
                          setSelectedBarList((c) =>
                            c.map((b) => {
                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: b.barQnty + 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );

                          setBarCodeList((c) =>
                            c.map((b) => {
                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: b.barQnty + 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );
                        }}
                        className="px-2 border-l h-full flex items-center bg-gray-100"
                      >
                        <Add
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end py-2 border-t ">
              <div className="flex border border-black rounded-sm overflow-hidden mr-3 ">
                <select onChange={handleSelectChange} className="outline-none">
                  {Object.keys(styles).map((key) => (
                    <option key={key} value={key}>
                      {styles[key].name}
                    </option>
                  ))}
                </select>
              </div>
              <ReactToPrint
                trigger={() => (
                  <button
                    // onClick={() => setPrintStatus(true)}
                    className=" text-black bg-blue-300 shadow-sm px-6  py-1 rounded"
                  >
                    Print
                  </button>
                )}
                content={() => printBarRef.current}
              />
            </div>
            {
              <div
                style={{
                  display: "none",
                }}
              >
                <PrintBarcodes
                  style={selectedStyle}
                  ref={printBarRef}
                  barcodes={selectedBarList}
                />
              </div>
            }
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BarcodeList;
