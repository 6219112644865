import { TextField, InputAdornment, IconButton, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import axios from "axios";

import { useState } from "react";
import Lottie from "lottie-react";
import spinner from "../utility/json/Spinner.json";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserType,
} from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { SelectDate } from "../utility/Redux/refresh";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
const HomeDashboard = () => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [todaySale, setTodaySale] = useState();
  const [sectionWiseSale, setsectionWiseSale] = useState([]);
  const [itemWiseSale, setitemWiseSale] = useState([]);
  const [todayOffer, settodayOffer] = useState([]);
  const [todayExpDelivery, setTodayExpectedDelivery] = useState([]);
  const [paymodeSummary, setpaymodeSummary] = useState([]);

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const companyDetail = useSelector(selectCompanyAddress);
  const [weeklyExpDelivery, setWeeklyExpDelivery] = useState([]);
  const [monthlyExpDelivery, setMonthlyExpDelivery] = useState([]);
  const [monthlyDelCount, setMonthlyDelCount] = useState([]);
  const [weeklyDelCount, setWeeklyDelCount] = useState([]);
  const [todayCanceledBookings, setTodayCanceledBookings] = useState([]);
  const [todayTry, setTodayExpectedTry] = useState([]);
  const [todayDeliveredItem, setTodayDeliverdItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkCurrentDate = useSelector(SelectDate);
  const [mobile, setMobile] = useState("");
  const [firstExpersion, setFirstExp] = useState(true);
  const defultProfile = useSelector(selectDefultProfileSetting);
  const userType = useSelector(selectUserType);
  const getTodaySale = () => {
    setLoading(true);
    axios
      .post(
        `/api/dashBoardRoute/todaySale`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodaySale(response.data.response[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayPaymentReciept();
      });
  };

  const getTodayPaymentReciept = () => {
    axios
      .post(
        `/api/saleReports/payModeSummary`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setpaymodeSummary(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getSectionWiseSale();
      });
  };

  const getSectionWiseSale = () => {
    axios
      .post(
        `/api/saleReports/sectionWiseSale`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          SECTION_NAME: "",
          CUSTOMER_MOBILE: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then(function (response) {
        if (!response.data.error) {
          setsectionWiseSale(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getitemWiseSale();
      });
  };

  const getitemWiseSale = () => {
    axios
      .post(
        `/api/saleReports/itemWiseSale`,
        {
          table_prefix: prefix,
          CUSTOMER_MOBILE: "",
          FDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDATE: moment(checkCurrentDate).add(7, "days").format("YYYY-MM-DD"),
          SECTION_NAME: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then(function (response) {
        if (!response.data.error) {
          setitemWiseSale(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayBooking();
      });
  };
  const getTodayBooking = (m) => {
    axios
      .post(
        `/api/offerMaster/listOffer`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          settodayOffer(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        settodayOffer([]);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayExpectedDelivery();
      });
  };

  const getTodayExpectedDelivery = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayExpDelivery`,
        {
          table_prefix: prefix,
          DEL_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayExpectedDelivery(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getWeeklyDelCount();
      });
  };

  const getWeeklyDelCount = () => {
    axios
      .post(
        `/api/dashBoardRoute/weeklyDeliveryCount`,
        {
          table_prefix: prefix,
          FDATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDATE: moment(checkCurrentDate).add(7, "days").format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setWeeklyDelCount(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getMonthlyDelCount();
      });
  };

  const getMonthlyDelCount = () => {
    axios
      .post(
        `/api/dashBoardRoute/monthlyDeliveryCount`,
        {
          table_prefix: prefix,
          FDEL_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          TDEL_DATE: moment(checkCurrentDate)
            .add(30, "days")
            .format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setMonthlyDelCount(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayCanceldBooking();
      });
  };

  const getTodayCanceldBooking = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayCanceledBookings`,
        {
          table_prefix: prefix,
          DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayCanceledBookings(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayExpectedTry();
      });
  };

  const getTodayExpectedTry = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayExpTry`,
        {
          table_prefix: prefix,
          TRY_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayExpectedTry(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayDeliveredItems();
      });
  };

  const getTodayDeliveredItems = () => {
    axios
      .post(
        `/api/dashBoardRoute/todayDelivered`,
        {
          table_prefix: prefix,
          F_DELIVERED_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          T_DELIVERED_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
          MOBILE: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setTodayDeliverdItem(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function setDeliveryStatusAPI(bookingNo, icode, status) {
    axios
      .post(
        `/api/booking/deliverItem`,
        {
          table_prefix: prefix,
          BOOKING_ORDER_NO: bookingNo,
          ICODE: icode,
          DELIVERED_YN: status,
          DELIVERED_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,

            status: false,
          });

          axios
            .post(
              `/api/dashBoardRoute/todayExpDelivery`,
              {
                table_prefix: prefix,
                DEL_DATE: moment(checkCurrentDate).format("YYYY-MM-DD"),
                MOBILE: mobile,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
              if (!response.data.error) {
                setTodayExpectedDelivery(response.data.response);
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error.response.data.name === "TokenExpiredError") {
                dispatch(setSessionExpired(true));
              }
            });
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 126",
              msg: response.data.error,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: "Error 125",
            msg: "Something went wrong while Updating Delivery status",
          })
        );
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      });
  }

  useEffect(() => {
    getTodaySale();
  }, [checkCurrentDate]);

  useEffect(() => {
    if (!mobile && !firstExpersion) {
      getTodayBooking();
    }
  }, [mobile]);

  return (
    <div className="h-full w-full text-md  overflow-y-scroll p-3  ">
      {todaySale ? (
        <div className="w-full">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1  text-white   rounded-br-3xl bg-[#634258]"
          >
            Today Sale :{" "}
            <div
              style={{
                fontWeight: "400",
              }}
              className="pl-2"
            >
              {FormatNumber2(
                todaySale.CASH_AMOUNT +
                  todaySale.CARD_AMOUNT +
                  todaySale.EPAYMENT +
                  todaySale.ADVANCE_AMT
              )}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todaySale ? (
              <>
                {todaySale.CASH_AMOUNT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",

                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] h-full rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Cash</div>
                        <div className="text-[#615b5b]">
                          {todaySale.CASH_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CASH_AMOUNT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.CARD_AMOUNT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between h-full w-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Card</div>
                        <div className="text-[#615b5b]">
                          {" "}
                          {todaySale.CARD_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CARD_AMOUNT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.EPAYMENT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">ePayment</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.EPAYMENT !== 0
                            ? FormatNumber2(todaySale.EPAYMENT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.ADVANCE_AMT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Advance</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.ADVANCE_AMT !== 0
                            ? FormatNumber2(todaySale.ADVANCE_AMT)
                            : null}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* Today Advance */}
      {paymodeSummary[0] ? (
        <div className="w-full mt-4">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit whitespace-nowrap flex  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Paymode Summary :{"  "}
            <div
              style={{
                fontWeight: "400",
              }}
              className="pl-2"
            >
              {/* {FormatNumber2(paymodeSummary[0].CREDIT)} */}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {paymodeSummary[0] ? (
              <>
                {paymodeSummary[0].CASH_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          Cash
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].CASH_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].CARD_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          Card
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].CARD_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].BTC_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          BTC
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].BTC_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].E_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          E Amount
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].E_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {sectionWiseSale[0] ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1  text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Section Wise Sale{" "}
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {sectionWiseSale.map((w, index) => (
              <Button
                key={index}
                variant={"contained"}
                style={{
                  textTransform: "none",

                  backgroundColor: "transparent",
                  padding: "0px",
                  marginBottom: "8px",
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    boxShadow: "1px 1px 1px gray",
                    backgroundColor: "#e8e2db",
                  }}
                  className=" justify-between w-full p-[0px] h-full rounded-sm"
                >
                  <div className="p-1 px-2 text-[#0d0c0c]">
                    <div className="text-start ">{w.SECTION_NAME}</div>
                    <div className="text-[#615b5b] flex">
                      Total :
                      <div className="flex ml-1 ">
                        {FormatNumber2(w.GTOTAL)}
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      ) : null}

      {itemWiseSale[0] ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex  px-3 py-1  text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Item Wise Sale{" "}
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {itemWiseSale.map((w, index) => (
              <Button
                key={index}
                variant={"contained"}
                style={{
                  textTransform: "none",

                  backgroundColor: "transparent",
                  padding: "0px",
                  marginBottom: "8px",
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    boxShadow: "1px 1px 1px gray",
                    backgroundColor: "#e8e2db",
                  }}
                  className="flex justify-between w-full p-[0px] h-full rounded-sm"
                >
                  <div className="p-1 px-2 text-[#0d0c0c]">
                    <div className="text-start ">{w.ITEMNAME}</div>
                    <div className="text-[#615b5b]">
                      <div className="flex ">
                        Total : {FormatNumber2(w.GTOTAL)}
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      ) : null}

      {/* Today Booking */}
      {todayOffer[0] ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit px-3 py-1 text-black   rounded-br-3xl bg-[#f8c469]"
          >
            Today Offer
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayOffer[0]
              ? todayOffer.map((e, index) => {
                  return (
                    <Button
                      key={index}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        marginBottom: "8px",
                        boxShadow: "none",
                      }}
                    >
                      <div
                        style={{
                          boxShadow: "1px 1px 1px gray",
                          backgroundColor: "#e8e2db",
                        }}
                        className=" justify-between w-full px-2 l text-sm pl-1 pb-2 pt-2 rounded-sm"
                      >
                        <div className=" w-full  flex  justify-between rounded-sm">
                          <div className="text-[#0d0c0c] text-start">
                            {e.ITEMNAME}
                          </div>
                        </div>
                        <div className="flex ">
                          <div className="text-red-500  text-start line-through ">
                            {FormatNumber2(e.MRP_RATE)}
                          </div>
                          <div className="text-[#0d0c0c] text-start ml-3 ">
                            {FormatNumber2(e.OFFER_SALE_PRICE)}
                          </div>
                        </div>

                        <div className="text-[14px] flex   text-black">
                          {e.FTIME} - {e.TTIME}
                        </div>
                      </div>
                    </Button>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {/* Today Delieveries */}

      {/* Today Try */}

      {todayCanceledBookings[0] ? (
        <div className="w-full mt-4">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Today canceled Booking
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayCanceledBookings.map((e, index) => {
              return (
                <Button
                  key={index}
                  variant={"contained"}
                  style={{
                    textTransform: "none",
                    backgroundColor: "transparent",
                    padding: "0px",
                    marginBottom: "8px",
                    boxShadow: "none",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "1px 1px 1px gray",
                      backgroundColor:
                        e.UPPER_LOWER === "UPPER" ? "#e8e2db" : "#f8e4dd",
                    }}
                    className="flex  h-full justify-between w-full p-[0px] rounded-sm"
                  >
                    <div className="rounded-sm  w-full p-1 px-2">
                      <div className="   flex  justify-between w-full ">
                        <div className="font-semibold text-[#0d0c0c ] text-start">
                          {e.ITEM_NAME + "-" + " " + e.QNTY}{" "}
                        </div>
                        <div>
                          <img
                            src={require(`../utility/images/${
                              e.UPPER_LOWER === "UPPER" ? "2" : "3"
                            }.png`)}
                            style={{
                              height: "25px",
                              width: "25px",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      ) : null}

      {weeklyDelCount[0] ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Weekly Delivery Count
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
              {weeklyDelCount.map((e, index) => {
                return (
                  <Button
                    key={index}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor:
                          e.UPPER_LOWER === "UPPER" ? "#e8e2db" : "#f8e4dd",
                      }}
                      className="flex h-full justify-between w-full pl-1 pt-2 pr-2 pb-2 rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + "-" + " " + e.NOOF_DELIVERY}{" "}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === "UPPER" ? "2" : "3"
                              }.png`)}
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      {loading && !monthlyDelCount[0] ? (
        <div className="w-6 h-6">
          <Lottie
            animationData={spinner}
            loop={true}
            style={{ fontSize: 5 }}
            color="gray"
          />
        </div>
      ) : monthlyDelCount[0] ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Monthly Delivery Count
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2 ">
              {monthlyDelCount.map((e, index) => {
                return (
                  <Button
                    key={index}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor:
                          e.UPPER_LOWER === "UPPER" ? "#e8e2db" : "#f8e4dd",
                      }}
                      className="flex justify-between h-full w-full pl-1 pr-2 pt-2 pb-2 rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + "-" + " " + e.NOOF_DELIVERY}{" "}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === "UPPER" ? "2" : "3"
                              }.png`)}
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {todayDeliveredItem[0] ? (
        <div className="w-full mt-4 ">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Today Delivered Item
          </div>
          <div className=" pr-2 mt-2 ">
            <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
              {todayDeliveredItem.map((e, index) => {
                return (
                  <Button
                    key={index}
                    onDoubleClick={() => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        title: "Delivery Status",
                        msg: "Do you want to cancel Delivery ?",
                        status: true,
                        button1: "YES",
                        button1Click: () => {
                          setDeliveryStatusAPI(
                            e.BOOKING_ORDER_NO,
                            e.ICODE,
                            "NO"
                          );
                        },
                        button2: "NO",
                        button2Click: "",
                      });
                    }}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor:
                          e.UPPER_LOWER === "UPPER" ? "#e8e2db" : "#f8e4dd",
                      }}
                      className="flex h-full justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="rounded-sm  w-full p-1 px-2">
                        <div className="   flex  justify-between w-full ">
                          <div className="font-semibold text-black text-start">
                            {e.ITEM_NAME + "-" + " " + e.QNTY}{" "}
                          </div>
                          <div>
                            <img
                              src={require(`../utility/images/${
                                e.UPPER_LOWER === "UPPER" ? "2" : "3"
                              }.png`)}
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                            />{" "}
                          </div>
                        </div>

                        <div className="text-sm text-start text-[#615b5b]">
                          {e.CUSTOMER_NAME}
                        </div>
                        <div className="text-start text-[#615b5b]">
                          {e.MOBILE}
                        </div>
                        <div className="flex justify-between ">
                          <div className="text-[#615b5b]"> {e.CITY}</div>
                          <div
                            className={`w-[18px] h-[18px] ${
                              e.DELIVERED_YN === "YES" ? "bg-green" : "bg-red"
                            } flex justify-center items-center  rounded-sm`}
                          >
                            {e.DELIVERED_YN === "YES" ? (
                              <i className="bi bi-check2 justify-center flex items-center text-white"></i>
                            ) : (
                              <i className="bi bi-x justify-center flex items-center text-white"></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default HomeDashboard;
