import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SaleSummaryColumns = (width) => {
  const columns = [
    {
      field: "INVNO",
      accessor: "INVNO",

      type: "string",
      headerAlign: "center",
      width: 100,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Invoice"}{" "}
        </div>
      ),
    },

    {
      field: "DATE",
      accessor: "DATE",
      type: "string",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Date"}{" "}
        </div>
      ),
    },
    {
      field: "BILL_TYPE",
      accessor: "BILL_TYPE",
      type: "string",
      headerAlign: "center",

      width: 80,
      headerClassName: "super-app-theme--header",

      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Bill Type"}{" "}
        </div>
      ),
    },

    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 130,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Mobile
        </div>
      ),
    },
    {
      field: "CUSTOMER_NAME",
      accessor: "CUSTOMER_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Name
        </div>
      ),
    },
    {
      field: "CUSTOMER_MOBILE",
      accessor: "CUSTOMER_MOBILE",
      type: "string",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Cust. Mobile
        </div>
      ),
    },
    {
      field: "TOTAL",
      accessor: "TOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? param.value + " %" : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST Amt.
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? param.value + " %" : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div
          className="text-white whitespace-normal  text-center"
          style={{ fontSize: 13 }}
        >
          SGST Amt.
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? param.value + " %" : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST Amt.
        </div>
      ),
    },

    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  const tabCloumns = [
    {
      field: "INVNO",
      accessor: "INVNO",

      type: "string",
      headerAlign: "center",
      width: 100,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Invoice"}{" "}
        </div>
      ),
    },

    {
      field: "DATE",
      accessor: "DATE",
      type: "string",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Date"}{" "}
        </div>
      ),
    },
    {
      field: "BILL_TYPE",
      accessor: "BILL_TYPE",
      type: "string",
      headerAlign: "center",

      width: 80,
      headerClassName: "super-app-theme--header",

      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Bill Type"}{" "}
        </div>
      ),
    },

    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: 225,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: 150,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Mobile
        </div>
      ),
    },
    {
      field: "CUSTOMER_NAME",
      accessor: "CUSTOMER_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Name
        </div>
      ),
    },
    {
      field: "CUSTOMER_MOBILE",
      accessor: "CUSTOMER_MOBILE",
      type: "string",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Cust. Mobile
        </div>
      ),
    },
    {
      field: "TOTAL",
      accessor: "TOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },

    {
      field: "TOTAL_TAX",
      accessor: "TOTAL_TAX",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Total Tax
        </div>
      ),
    },

    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];

  const mobileColumns = [
    {
      field: "INVNO",
      accessor: "INVNO",

      type: "string",
      headerAlign: "center",
      width: width / 7,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Invoice"}{" "}
        </div>
      ),
    },

    {
      field: "DATE",
      accessor: "DATE",
      type: "string",
      type: "date",
      headerAlign: "center",

      width: width / 4,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Date"}{" "}
        </div>
      ),
    },
    {
      field: "BILL_TYPE",
      accessor: "BILL_TYPE",
      type: "string",
      headerAlign: "center",

      width: width / 4,

      headerClassName: "super-app-theme--header",

      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Bill Type"}{" "}
        </div>
      ),
    },

    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width > 450 ? width / 4 - 40 : width / 4,

      align: "right",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  return width < 500 ? mobileColumns : width < 1281 ? tabCloumns : columns;
};
