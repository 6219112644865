import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import axios from "axios";
import { useEffect } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import CallAPI from "../../utility/functions/getData";
import { useFocus } from "../../utility/hooks/hooks";
const HsnMaster = ({ onClose, afterCreate }) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [id, setId] = useState();
  const [hsnCode, sethsnCode] = useState();
  const [taxRate, setTaxRate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [fullList, setFullList] = useState([]);
  const navigate = useNavigate();
  const windowWidth = useSelector(selectWidth);
  const [hsnRef, setHsmFocus] = useFocus();
  const [taxRateRef, setTaxRateFocus] = useFocus();
  const columns = [
    {
      field: "HSN_CODE",
      type: "string",
      renderHeader: (params) => <div>HSN Code</div>,
      width: windowWidth > 768 ? 300 : 200,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: "TAX_RATE",
      type: "string",
      renderHeader: (params) => <div>Tax Rate</div>,
      width: windowWidth > 768 ? 300 : 100,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const saveSectionMasterApi = () => {
    if (hsnCode && taxRate) {
      const isDuplicate = rows.filter(
        (e) =>
          Number(e.HSN_CODE) === Number(hsnCode) &&
          Number(e.TAX_RATE) === Number(taxRate)
      );
      if (!isDuplicate[0]) {
        const data = {
          table_prefix: prefix,
          CODE: id ? id : "",
          HSN_CODE: hsnCode,
          TAX_RATE: taxRate,
        };
        id
          ? dispatch(setSpinnerLoading("Updating"))
          : dispatch(setSpinnerLoading("Saving"));
        axios
          .post(`/api/master/HsnCodeMaster`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async function (response) {
            if (!response.data.error) {
              id
                ? dispatch(
                    setAlertWithTitle({
                      title: "Success.",
                      msg: "Data Updated successfully.",
                      lottie: "success",
                    })
                  )
                : dispatch(
                    setAlertWithTitle({
                      title: "Success.",
                      msg: "Data Saved successfully.",
                      lottie: "success",
                    })
                  );
              setTaxRate("");
              listSectionMasterApi();
              if (afterCreate) {
                afterCreate();
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Error 112",
                  msg: response.data.error,
                })
              );
              console.log(!response.data.error);
            }
          })
          .catch(function (error) {
            if (error.response.data.name === "TokenExpiredError") {
              dispatch(setSessionExpired(true));
              console.log(error.response);
            }
            console.log(error);
            dispatch(
              setAlertWithTitle({
                title: "ERROR",
                msg: "Something went wrong.",
                lottie: "reject",
              })
            );
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
            sethsnCode("");
            setId("");
            listSectionMasterApi();
          });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Already exist same record.",
          })
        );
      }
    } else {
      if (!hsnCode) {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Hsn Code.",
          })
        );
        setHsmFocus();
      } else {
        if (!taxRate) {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Enter Tax Rate.",
            })
          );
          setTaxRateFocus();
        }
      }
    }
  };

  const listSectionMasterApi = () => {
    const data = {
      table_prefix: prefix,
      HSN_CODE: "",
    };
    axios
      .post(`/api/master/listHSNCodeMaster`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;

          setRows(data);
          setFullList(data);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        sethsnCode("");
        setId("");
      });
  };

  useEffect(() => {
    listSectionMasterApi();
  }, []);

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        saveSectionMasterApi();
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [hsnCode, taxRate]);
  const isPadding = window.location.pathname.includes("hsn_master");
  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.HSN_CODE.toString().includes(searchText);
        } else {
          return item.HSN_CODE.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchText]);
  return (
    <div className="w-full  flex justify-center bg-[#f5f5f7] h-full ">
      <div
        style={{
          padding: isPadding ? "16px" : "0px",
        }}
        className="text-sm  rounded-sm   flex flex-col   h-full "
      >
        <div>
          <ModalHeaderStyle
            header={"HSN Master"}
            onClose={() => {
              if (onClose) {
                onClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 160px)",
          }}
          className=" bg-[#fdfffe] rounded-b-sm   border-brown p-3 max-sm:px-2"
        >
          <div id="input" className="flex ">
            <div className="flex">
              <GeniustextInput
                title={"HSN Code"}
                width={windowWidth > 768 ? 300 : 200}
                type={"text"}
                value={hsnCode}
                refrance={hsnRef}
                autofocus={true}
                onChange={(e) => {
                  sethsnCode(e.target.value);
                }}
              />
              <div className="ml-2">
                <GeniustextInput
                  refrance={taxRateRef}
                  title={"Tax Rate"}
                  width={windowWidth > 768 ? 300 : 100}
                  type={"text"}
                  value={taxRate}
                  onChange={(e) => {
                    setTaxRate(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              height: "calc(100% - 135px)",
            }}
            className="w-full  mt-1 bg-white flex"
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              showPagination={true}
              onRowClick={(p) => {
                setId(p.row.CODE);
                sethsnCode(p.row.HSN_CODE);
                setTaxRate(p.row.TAX_RATE);
              }}
              columns={columns}
              list={rows}
            />
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid #D2D2D7",
            }}
            className="rounded-sm p-1 mt-3 flex items-center justify-between  pl-2"
          >
            {" "}
            <div className=" ">
              <input
                type="text"
                value={searchText}
                className="w-full"
                style={{
                  outline: "none ",
                }}
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className="text-sm pr-3 text-gray-500">
              Records : {rows.length}
            </div>
          </div>
          <div className="flex w-full justify-end items-center pt-3">
            {id ? (
              <ModifyButton onClick={saveSectionMasterApi} />
            ) : (
              <SaveButton onClick={saveSectionMasterApi} />
            )}

            <UndoButton
              onClick={() => {
                sethsnCode("");
                setId("");
                setTaxRate("");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HsnMaster;
